import React from "react";
import "./App.scss";
// @ts-ignore
// import { BrowserRouter as Router, Route, NavLink, Switch, useHistory } from "react-router-dom";
import { Provider as AuthProvider } from "./context/AuthContext";
import { Provider as SensorProvider } from "./context/SensorContext";
import { Provider as SettingsProvider } from "./context/SettingsContext";

import Routes from "./Routes";

function App(props: any) {
  return (
    <AuthProvider>
      <SensorProvider>
        <SettingsProvider>
          <Routes />
          {/* <Main /> */}
        </SettingsProvider>
      </SensorProvider>
    </AuthProvider>
  );
}

export default App;
